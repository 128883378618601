import React from "react"
import SEO from "../../../../components/seo"
import WECPDSLayout from "../../../../components/wecp/ds-layout"


const ButtonDS = () => (
  <>
    <WECPDSLayout>
      <div>ASDASDASDAS</div>
    </WECPDSLayout>
  </>
)

export default ButtonDS
